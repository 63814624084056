<template>
    <b-card-code no-body title="Create new administrator">
        <b-row class="pb-2">
            <b-col md="6">
                <div class="px-2">
                <!-- form -->
                <validation-observer ref="adminCreateForm">
                    <b-form
                    class="auth-register-form mt-2"
                    @submit.prevent="createAdmin"
                    >
                    <!-- fullname -->
                    <b-form-group>
                        <validation-provider
                        #default="{ errors }"
                        name="Full name"
                        vid="fullname"
                        rules="required"
                        >
                        <b-form-input
                            id="register-fullname"
                            v-model="fullname"
                            name="register-fullname"
                            :state="errors.length > 0 ? false : null"
                            placeholder="Full Name"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>

                    <!-- username -->
                    <b-form-group>
                        <validation-provider
                        name="Username"
                        vid="username"
                        rules="required"
                        >
                        <b-form-input
                            id="register-username"
                            v-model="username"
                            name="register-username"
                            :state="errorExists"
                            placeholder="Username"
                            @keyup='checkUsername()'
                        />
                        <small class="text-danger">{{checkUsernameError}}</small>
                        </validation-provider>
                    </b-form-group>

                    <!-- email -->
                    <b-form-group>
                        <validation-provider
                        #default="{ errors }"
                        name="Email"
                        vid="email"
                        rules="required|email"
                        >
                        <b-form-input
                            id="register-email"
                            v-model="userEmail"
                            name="register-email"
                            :state="errors.length > 0 ? false : null"
                            placeholder="Email"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>

                    <!-- password -->
                    <b-form-group >
                        <validation-provider
                        #default="{ errors }"
                        name="Password"
                        rules="required|password"
                        >
                        <b-input-group
                            class="input-group-merge"
                            :class="errors.length > 0 ? 'is-invalid' : null"
                        >
                            <b-form-input
                            id="password"
                            v-model="password"
                            :type="passwordFieldType"
                            :state="errors.length > 0 ? false : null"
                            class="form-control-merge"
                            name="password"
                            placeholder="Password"
                            />
                            <b-input-group-append is-text>
                            <feather-icon
                                :icon="passwordToggleIcon"
                                class="cursor-pointer"
                                @click="togglePasswordVisibility"
                            />
                            </b-input-group-append>
                        </b-input-group>
                        <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>

                    <!-- confirm password -->
                    <b-form-group>
                        <validation-provider
                        #default="{ errors }"
                        name="Confirm Password"
                        rules="required|confirmed:Password"
                        >
                        <b-input-group
                            class="input-group-merge"
                            :class="errors.length > 0 ? 'is-invalid' : null"
                        >
                            <b-form-input
                            id="password-confirm"
                            v-model="cPassword"
                            :type="cPasswordFieldType"
                            :state="errors.length > 0 ? false : null"
                            class="form-control-merge"
                            name="password-confirm"
                            placeholder="Confirm password"
                            />
                            <b-input-group-append is-text>
                            <feather-icon
                                :icon="cPasswordToggleIcon"
                                class="cursor-pointer"
                                @click="toggleCPasswordVisibility"
                            />
                            </b-input-group-append>
                        </b-input-group>
                        <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>

                    <b-form-group>
                        <v-select
                            v-model="selected_permissions"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            multiple
                            label="name"
                            :options="permissions"
                            placeholder="Permissions"
                        />
                    </b-form-group>
                    
                    <b-form-group>
                        <v-select
                            v-model="selected_roles"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            multiple
                            label="name"
                            :options="roles"
                            placeholder="Roles"
                        />
                        <small class="text-danger">{{ rolesError }}</small>
                    </b-form-group>

                    <b-button
                        variant="primary"
                        type="submit"
                        :disabled="btnDisabled"
                    >
                        Create account
                    </b-button>
                    <div
                        v-if="loading"
                        class="d-flex justify-content-center mt-2"
                    >
                        <b-spinner variant="primary" label="Loading..." />
                    </div>
                    </b-form>
                </validation-observer>
                </div>
            </b-col>
        </b-row>
    </b-card-code>
</template>

<script>
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import { BButton, BFormInput, BForm, BFormGroup, BRow, BCol, BInputGroup, BInputGroupAppend } from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { required, email } from "@validations";
import { BSpinner } from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import vSelect from 'vue-select'

export default {
    components: {
        BCardCode,
        BRow,
        BCol,
        BButton,
        BForm,
        BFormGroup,
        BFormInput,
        BInputGroup,
        ValidationProvider,
        ValidationObserver,
        BInputGroupAppend,
        BSpinner,
        vSelect
    },
    data() {
        return {
            fullname: "",
            username: "",
            userEmail: "",
            password: "",
            cPassword: "",
            selected_permissions: null,
            permissions: [],
            selected_roles: null,
            roles: [],

            // validation
            required,
            email,

            btnDisabled: false,
            loading: false,
            isAvailableUsername: 1,
            errors: [],
            slugFlag: false,
            slugString: "",
            rolesError: "",

            // Toggle Password
            passwordFieldType: 'password',
            cPasswordFieldType: 'password',
        }
    },
    computed: {        
        passwordToggleIcon() {
            return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
        },
        cPasswordToggleIcon() {
            return this.cPasswordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
        },
        checkUsernameError() {
            return this.errors[0]
        },
        errorExists() {
            if(this.username.length > 0) {
                if(this.isAvailableUsername == 0) {
                  this.errors.push('That username already exists')
                  return false
                } else {
                  this.errors = []
                  return null
                }
            }
        }
    },
    created() {
        //Return permissions list
        this.$http.get("/api/auth/admin/permissions")
            .then((res) => {
                if(res != undefined) {
                    let preparedPermissions = [];
                    for(let i=0; i<res.data.length; i++) {
                        preparedPermissions.push(res.data[i].name)
                    }
                    this.permissions = res.data
                }
            })
            .catch((err) => {
                console.log(err)
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: "Something went wrong, please login again",
                        icon: "AlertCircleIcon",
                        variant: "danger",
                    },
                });
            })
        
        //Return roles list
        this.$http.get("/api/auth/admin/roles")
            .then((res) => {
                if(res != undefined) {
                    let preparedRoles = [];
                    for(let i=0; i<res.data.length; i++) {
                        preparedRoles.push(res.data[i].name)
                    }
                    this.roles = res.data
                }
            })
            .catch((err) => {
                console.log(err)
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: "Something went wrong, please login again",
                        icon: "AlertCircleIcon",
                        variant: "danger",
                    },
                });
            })
    },
    methods: {
        // Toggle password visibility
        togglePasswordVisibility() {
            this.passwordFieldType = this.passwordFieldType === 'password' ? 'text' : 'password'
        },
        toggleCPasswordVisibility() {
            this.cPasswordFieldType = this.cPasswordFieldType === 'password' ? 'text' : 'password'
        },

        // Request to create admin
        createAdmin() {                
            this.rolesError = ""

            let admin = {
                "name": this.fullname,
                "email": this.userEmail,
                "password": this.password,
                "c_password": this.cPassword,
                "permissions": this.selected_permissions,
                "roles": this.selected_roles,
                "username": this.username,
                "role": "admin"
            }

            if(this.username.length == 0) {
                this.errors.push('The username field must not be empty!')
                return
            }

            this.$refs.adminCreateForm.validate().then(success => {
                if (success) {

                    this.$http.post("/api/auth/admin/users", admin)
                    .then((res) => {
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: "Admin successfully created",
                                icon: "CheckSquareIcon",
                                variant: "success",
                            },
                        });

                        // Reset form
                        this.fullname = ""
                        this.userEmail = ""
                        this.password = ""
                        this.cPassword = ""
                        this.selected_permissions = []
                        this.selected_roles = []
                        this.username = ""

                        this.$router.push("/admins");
                    })
                    .catch((error) => {
                        this.show = false
                        
                        let errorMsg = "There was an error!"
                        if(error.response != undefined) {
                            errorMsg = error.response.data.errors.email[0]
                        }
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: errorMsg,
                                icon: "AlertCircleIcon",
                                variant: "danger",
                            },
                        });
                    });
                }
            });
        },
        checkUsername() {
            let username = this.username.trim()

            if(username != '' && !username.includes(" ")) {
                this.$http.get(`/api/user/username/${username}`)
                .then((res) => {
                    this.isAvailableUsername = 1
                    this.errors = []
                }).catch((error) => {
                    this.isAvailableUsername = 0
                    if(error.response.data.message == "Username is taken") {
                        this.errors[0] = 'That username already exists'
                    } else if (error.response.data.message == "The given data was invalid.") {
                        this.errors[0] = 'Swear and bad words not allowed'
                    } else {
                        this.errors[0] = 'Something is wrong, please try again.'
                    }
                })
            }

            if(username.includes(" ")) {
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: "Username must not contain spaces!",
                        icon: "AlertCircleIcon",
                        variant: "danger",
                    },
                });
            }

        },
    },
}
</script>

<style scoped>
    option[disabled] { background-color: #D0D0D0; }
</style>

<style>
    .vs__selected {
        background-color: #59949c!important;
    }
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
